import { createStore } from 'vuex'
import web3 from "web3"
//import web3_eth from "web3-eth"
export default createStore({
  state: {
    //navbar
    hiddenPercentage : 0,
    //web3 Object
    web3: null,
    //Constants for Network Description
    networks: {
      '1': 'Main Net',
      '3': 'Ropsten test network',
      '4': 'Rinkeby test network'
    },
    contractWorkflowStatus: [
    "Before",
    "Presale",
    "Sale",
    "SoldOut",
    "Reveal"],

    //State of Wallet Connection
    wallet: {
      id: "",
      state: 4,
      error: ""
    },
    //State of Contract Connection
    contract: {
      status: "",
      numberMinted: "",
      maximumForMint: 3,
      mintStatus: "",
      errorReadingStats: "",
      linkToEtherscan: "",
      contractAdress: process.env.VUE_APP_CONTRACTADRESS,
    }
  },
  mutations: {
    setHiddenPercentage(state, aPercentage){
      state.hiddenPercentage = aPercentage;
    },
    //Mutations for Web3 Object
    setWeb3(state, web3){
      state.web3 = web3;
    },

    //Mutations for Wallet Interaction
    setWalletId(state, id) {
      state.wallet.id = id;
    },
    setWalletState(state, status) {
      state.wallet.state = status;
    },
    setWalletError(state, error) {
      state.wallet.error = error;
    },

    //Mutations for Contract Interaction
    setContractStatus(state, status) {
      state.contract.status = status;
    },

    setNumberMinted(state, num) {
      state.contract.numberMinted = num;
    },

    setErrorReadingStats(state, err){
      state.contract.errorReadingStats = err;
    },
    
    setContractMintStatus(state, mintStatus) {
      state.contract.mintStatus = mintStatus;
    },
    setContractLinkToEtherscan(state, linkToEtherscan) {
      return state.contract.linkToEtherscan = linkToEtherscan;
    }

  },
  getters: {
    getHiddenPercentage(state){
      return state.hiddenPercentage;
    },
    //Getters for Wallet Interaction
    getWalletId(state) {
      return state.wallet.id;
    },
    getWalletState(state) {
      return state.wallet.state;
    },
    getWalletStateDescription(state) {
      var messages = ["Please install Metamask.",
        "Connected: " + state.wallet.id + ". To Disconnect please remove this site from Connected Sites in Metamask.",
        "😥 " + state.wallet.error,
        "Please connect using button",
        "You are not connected to " + state.networks[process.env.VUE_APP_NETWORK] + '. Please switch Network in Metamask.']
      return messages[state.wallet.state];
    },
    //Mutations for Contract Interaction
    getContractStatus(state) {
     return state.contract.status;
    },

    getErrorReadingStats(state){
      return state.contract.errorReadingStats;
    },

    getContractStatusDescription(state) {
      return state.contractWorkflowStatus[state.contract.status];
     },

     getNumberMinted(state) {
      return state.contract.numberMinted;
     },
     getMaximumForMint(state) {
      return state.contract.maximumForMint;
     },
         
    getContractMintStatus(state) {
      return state.contract.mintStatus;
    },
    getContractLinkToEtherscan(state) {
      return state.contract.linkToEtherscan;
    }
  },
  actions: {
    //Actions for Wallet Interaction
    async connectRegisteredWallet({ commit, dispatch }) {
      if (window.ethereum) {
        try {
          const addressArray = await window.ethereum.request({
            method: "eth_accounts",
          });
          var wallet = addressArray[0];
          if (wallet) {
            commit("setWalletId", wallet);
            commit("setWalletState", 1);
            dispatch("checkNetwork");
            dispatch("listenToWalletChange");
          } else {
            commit("setWalletState", 3);
          }

        } catch (err) {
          commit("setWalletError", err.message);
          commit("setWalletState", 2);
        }
      } else {
        commit("setWalletState", 0);
      }
    },

    async connectNewWallet({ commit, dispatch }) {
      if (window.ethereum) {
        try {
          const addressArray = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          var wallet = addressArray[0];
          commit("setWalletId", wallet);
          commit("setWalletState", 1);
          dispatch("checkNetwork");
          dispatch("listenToWalletChange");
        } catch (err) {
          commit("setWalletError", err.message);
          commit("setWalletState", 2);
        }
      } else {
        commit("setWalletState", 0);
      }
    },

    listenToWalletChange({ commit, dispatch }) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          try {
            var wallet = accounts[0];
            commit("setWalletId", wallet);
            commit("setWalletState", 1);
          } catch (err) {
            commit("setWalletError", err.message);
            commit("setWalletState", 2);
          }
        } else {
          commit("setWalletState", 3);
        }
      });
      window.ethereum.on('chainChanged', () => {
        dispatch("checkNetwork");
      });
    },

    async checkNetwork({ commit, dispatch }) {
      var chainId = await window.ethereum.request({ method: "eth_chainId" });
      chainId = parseInt(chainId, 16);
      var network = parseInt(process.env.VUE_APP_NETWORK, 10);
      if (chainId !== network) {
        commit("setWalletState", 4);
        if ((await dispatch("switchNetwork", network))) {
          commit("setWalletState", 1);
        }
      }
    },
    async switchNetwork(commit, chain) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: '0x' + chain.toString(16) }],
        });
        return 1;
      } catch (switchError) {
        return 0;
      }
    },

    //Actions for Contract Interaction
    initContract({commit}) {
      //TBD: Darf der Alyhemy Key öffentlich gemacht werden?
      const alchemyKey = process.env.VUE_APP_API_URL;
      const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
      const web3 = createAlchemyWeb3(alchemyKey);
      commit("setWeb3", web3);
      const contractABI = require("../contract/contract.json");
      window.contract = new web3.eth.Contract(contractABI, this.state.contract.contractAdress);
      console.log(window.contract);
    },

    async readContractStatus({commit}) {
      const transactionParameters = [{
        to: this.state.contract.contractAdress,
        data: await window.contract.methods.getSaleStatus().encodeABI()
      },
        "latest"];
      try {
        const result = await window.ethereum.request({
          method: "eth_call",
          params: transactionParameters,
        });
        commit("setContractStatus" ,web3.utils.hexToNumber(result));
      } catch (error) {
        commit("setErrorReadingStats", "😥 Could not get contract status: " + error.message);
      }
    },

    async readNumberMinted({commit}) {
      const transactionParameters = [{
        to: this.state.contract.contractAdress,
        data: await window.contract.methods.totalSupply().encodeABI()
      },
        "latest"];
      try {
        const result = await window.ethereum.request({
          method: "eth_call",
          params: transactionParameters,
        });
        commit("setNumberMinted", web3.utils.hexToNumber(result));
      } catch (error) {
        commit("setErrorReadingStats", "😥 Could not get number of already minted NFTs: " + error.message);
      }
    },


    async whitelistSale({commit, dispatch }, signature) {
     try {
      //check if already sold out
      await dispatch("readContractStatus");
      if (this.state.contract.status > 2){
        throw new Error("Sale closed!");
      } 
      //check whitelist code
      var encoded = this.state.web3.eth.abi.encodeParameters(['address'],[window.ethereum.selectedAddress]);
      var messageHash = web3.utils.sha3(encoded, {encoding: 'hex'})
      if (process.env.VUE_APP_CONTRACTOWNER  != this.state.web3.eth.accounts.recover(messageHash, signature)){
        throw new Error("Incorrect Whitelist key!");
      }
      const transactionParameters = {
        from: window.ethereum.selectedAddress,
        to: this.state.contract.contractAdress,
        value: parseInt(web3.utils.toWei(String(0.05 * 1), "ether")).toString(16), //16 for HEX
        //Number of NFTs to Mint
        data: await window.contract.methods.presaleMint(messageHash, signature).encodeABI(),
      };
      dispatch("signTransaction", transactionParameters);

    } catch (error) {
      commit("setContractMintStatus", "😥 Something went wrong: " + error.message);
      commit("setContractLinkToEtherscan", "");
    }

    },

    async mintPublicSale({ commit, dispatch }, number) {
      try {
      //check if already sold out
      await dispatch("readContractStatus");
      if (this.state.contract.status > 2){
        throw new Error("Sale closed!");
      } 
      const transactionParameters = {
        from: window.ethereum.selectedAddress,
        to: this.state.contract.contractAdress,
        value: parseInt(web3.utils.toWei(String(0.2 * number), "ether")).toString(16), //16 for HEX
        //Number of NFTs to Mint
        data: await window.contract.methods.publicSaleMint(number).encodeABI(),
      };
      dispatch("signTransaction", transactionParameters);
    } catch (error) {
      commit("setContractMintStatus", "😥 Something went wrong: " + error.message);
      commit("setContractLinkToEtherscan", "");
    }
    },

    async signTransaction({ commit }, transactionParameters) {
      //sign transaction via Metamask
      try {
        const txHash = await window.ethereum.request({
          method: "eth_sendTransaction",
          params: [transactionParameters],
        });
        commit("setContractMintStatus", "✅ Check out your transaction on Etherscan: ");
        commit("setContractLinkToEtherscan", " https://ropsten.etherscan.io/tx/" + txHash);
      } catch (error) {
        commit("setContractMintStatus", "😥 Something went wrong: " + error.message);
        commit("setContractLinkToEtherscan", "");
      }
    }
  },
  modules: {
  }
})