<template>
  <router-view/>
</template>
<script>

export default {
  name: 'App',
  components: {
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 150%;
}
body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

body::-webkit-scrollbar-thumb {
  background: #aaaaaa;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background: #606060;
}
body{
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
  color: rgb(74, 74, 76);
}
.CategorySection{
  padding-top: 160px;
  padding-bottom: 160px;
}
@media screen and (max-width: 800px) {
  .CategorySection{
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
h1{
  margin-bottom: 15px;
}
h3{
  margin-bottom: 10px;
}
.animate-text-block{
  position:relative;
  animation:animateTextBlock 1s;
}
@keyframes animateTextBlock{
  from{bottom:-20px;opacity:0}
  to{bottom:0;opacity:1;}
}
.opacityFadeEffect{
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
</style>